<template>
  <div class="mt-1">
    <div class="parent">
      <div class="banner d-flex">
        <a class="btn btn-link btn-sm" style="color: #ffffff" @click="goBack()">
          <svg
            fill="#fff"
            class="material-design-icon__svg"
            width="15"
            height="15"
            viewBox="0 0 24 24"
          >
            <path
              d="M20,11V13H8L13.5,18.5L12.08,19.92L4.16,12L12.08,4.08L13.5,5.5L8,11H20Z"
            ></path>
          </svg>
          <span> Go Back</span>
        </a>
      </div>
      <iframe
        allow="fullscreen"
        v-show="isLoaded"
        class="iframe-container"
        v-bind:src="launchURL"
        allowfullscreen
        webkitallowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
import launch from "@/services/launch";

import walletserve from "@/services/walletserve";

export default {
  name: "CasinoGames",
  components: {},
  data: function () {
    return {
      loading: "",
      gameName: "",
      gameID: "",
      icon: "",
      isLoaded: false,
      launchURL: "",
      launchParameters: {},
      launchType: 1,
      isDemo: 0,

      amount: "",
    };
  },
  mounted() {
    this.$store.dispatch("setCurrentPage", "casinogames");
    this.reloadProfile();
    window.addEventListener("message", this.handlePostMessage);
  },

  beforeDestroy() {
    window.removeEventListener("message", this.handlePostMessage);
  },
  computed: {
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // react to route changes...
        this.gameName = to.params.gameName;
        this.gameID = to.params.gameID;
        this.launchParameters = to.params.launchParameters;
        this.launchType = to.params.launchType;
        this.isDemo = to.params.IsDemo;

        let payload = {
          provider_id: to.params.providerID,
          game_name: to.params.gameName,
          game_id: to.params.gameID,
          is_demo: to.params.isDemo,
        };

        this.getLaunchUrl(payload);
      },
    },
  },
  methods: {
    handlePostMessage(event) {
      console.log("Received message:", event.data, typeof event.data);

      let messageData = event.data; // Directly use event.data

      try {
        if (
          messageData.type === "rgs-backToHome" &&
          messageData.mainDomain // Ensure mainDomain exists
        ) {
          // const exitUrl = messageData.mainDomain; // Extract the exit URL directly
          const exitUrl = window.location.origin + "/casino"; // Extract the exit URL directly

          console.log("Redirecting to:", exitUrl);
          window.location.href = exitUrl; // Redirect the user
        }
      } catch (error) {
        console.error(
          "Error processing message:",
          error,
          "Raw data:",
          event.data
        );
      }
    },

    setAmount: function (amountToAdd) {
      // Convert the current amount to a number
      let currentAmount = parseFloat(this.amount);

      // Check if the current amount is a valid number
      if (isNaN(currentAmount)) {
        currentAmount = 0; // Set it to 0 if it's not a valid number
      }

      // Add the new amount to the existing amount
      currentAmount += amountToAdd;

      // Update the amount with the new total
      this.amount = currentAmount.toFixed(2); // Limit to 2 decimal places
    },

    deposit: function () {
      this.reset();
      var p = this.getAuth();
      if (!p) {
        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.amount < 10) {
        this.setError("Invalid Amount", "Enter amount atleast 10 KSH or above");
        return;
      }

      var vm = this;
      var path = "/deposit/initiate";

      var data = {
        amount: parseInt(this.amount),
      };

      vm.loading = "loading";
      console.log(this.getAuth());

      walletserve
        .post(path, JSON.stringify(data), {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          vm.loading = "";
          var msg = res.data.data;
          console.log(msg);
          vm.setSuccess("Success", msg);
        })
        .catch((err) => {
          vm.loading = "";
          if (err.response) {
            if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              vm.setError(
                "Session Expired",
                "Your session on this device has expired"
              );
              vm.logout();
              return;
            } else {
              vm.setError("Failed", err.response.data.error_message);
              // console.log(JSON.stringify(err.response.data.message));
            }
          } else if (err.request) {
            vm.setError(
              "Network Error",
              "Check your network connection and try again"
            );
            // console.log(JSON.stringify(err.request));
          } else {
            // vm.setError(
            //   "Network Error",
            //   "Check your network connection and try again three"
            // );
            console.log(JSON.stringify(err));
          }
        });
    },

    getLaunchUrl(data) {
      console.log("Launch " + JSON.stringify(data, undefined, 2));

      if (!this.profile) {
        this.setError("Login", "Please login to proceed");
        this.setValue("placeBet", 3);
        this.$router.push({ name: "login", params: {} });
        return;
      }
      function detectDeviceType() {
        const userAgent = navigator.userAgent.toLowerCase();
        if (/mobile/i.test(userAgent)) {
          return "mobile";
        } else {
          return "desktop";
        }
      }

      var providerId = data.provider_id;
      var gameId = data.game_id;
      var gameName = data.game_name;
      var accountID = parseInt(process.env.VUE_APP_CASINO_ACCOUNT_ID);

      let payload = {};
      console.log("RESGAME:::", data.type);
      console.log("RESGAME:::" + data.type);

      if (data.game_id == "11997") {
        console.log("Worked");
        payload = {
          account_id: accountID,
          demo: 0,
          device: detectDeviceType(),
          game_id: [String(data.game_id)],
          game_name: [data.game],
          return_url: window.location.origin + "/casino",
          lobby_url: window.location.origin + "/casino",
          provider_id: providerId,
        };
      } else {
        console.log("NOT Worked");

        payload = {
          account_id: accountID,
          demo: 0,
          device: detectDeviceType(),
          game_id: String(gameId),
          game_name: gameName,
          return_url: window.location.origin + "/casino",
          lobby_url: window.location.origin + "/casino",
          provider_id: providerId,
        };
      }

      console.log(
        "provider " +
          providerId +
          " | payload " +
          JSON.stringify(payload, undefined, 2)
      );

      return this.launchGame(payload); // Return the launch URL
    },

    launchGame: function (payload) {
      var vm = this;

      var service;

      service = launch;

      service
        .post("/launch/url", payload, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((response) => {
          vm.launchURL = response.data.game_url;
          console.log("LaunchURL", vm.launchURL);

          if (vm.launchType === "3") {
            window.open(this.launchURL);
          }

          console.log("LaunchUrl", vm.launchURL);
          this.isLoaded = true; // Set isLoaded to true after getting the launch URL
          return vm.launchURL;
        })
        .catch((error) => {
          console.error("Error launching game:", error);
        });
    },

    goToCasino: function () {
      this.$router.push({ name: "casino", params: {} });
    },

    showDeposit: function () {
      document.getElementById("deposit-init").click();
    },
  },
  filters: {
    amount: function (val) {
      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },
};
</script>

<style scoped>
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, 50px);
}

.modal-dialog {
  position: fixed !important;
  bottom: 0 !important;
  left: 0% !important;
  right: 0% !important;
  margin-bottom: 0 !important;
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-content {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: var(--gray-white);
}

.list-active {
  background-color: #f08b05;
}

.title_name {
  color: #fff;
}

.parent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.parent .banner {
  width: 100%;
  /* height: 48px; */
}

.parent iframe {
  background: #000;
  border: none;
  flex-grow: 1;
}

h4 {
  position: relative;
  top: 0.6em;
  right: 1em;
}
</style>
